html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}
body {
	margin: 0;
}
html,
body {
	font-family: Verdana, sans-serif;
	font-size: 15px;
	line-height: 1.5;
}
html {
	overflow-x: hidden;
}
.title {
	font-weight: 700;
	text-align: center;
}
.flex-parent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
@media (min-width: 600px) {
	.flex-parent {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
	}
}
.dates {
	display: flex;
	flex-direction: row;
}
.date {
	flex: 1;
}
h1 {
	font-size: 36px;
}
h2 {
	font-weight: 400;
	font-size: 30px;
}
h3 {
	font-style: italic;
	font-size: 24px;
}
h4 {
	font-size: 20px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
.serif {
	font-family: serif;
}
.sans-serif {
	font-family: sans-serif;
}
.cursive {
	font-family: cursive;
}
.monospace {
	font-family: monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Segoe UI", Arial, sans-serif;
	margin: 0 0;
}
.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
}
.modal-body {
	position: relative;
	padding: 0px;
}
.close {
	position: absolute;
	right: -30px;
	top: 0;
	z-index: 999;
	font-size: 2rem;
	font-weight: normal;
	color: #fff;
	opacity: 1;
}
.wide {
	letter-spacing: 4px;
	min-width: 300px;
}
.address {
	font-size: 16px;
}
.film-info {
	margin-top: 25px;
	padding: 5px;
}
.poster {
	width: 150px;
	height: auto;
	margin-bottom: 32px;
}
@media (min-width: 600px) {
	.poster {
		width: 250px;
		height: auto;
		margin-right: 25px;
		margin-bottom: 25px;
	}
}
.film-text {
	flex: 1;
}
.same-film {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0px;
}
.sponsors {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	max-width: 1200px;
	margin: auto;
}
.sponsors img {
	max-width: 10%;
	height: auto;
}

.contact {
	padding-top: 32px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	max-width: 900px;
	margin: auto;
}
.contact-info {
	padding-top: 16px;
}
@media (min-width: 600px) {
	.contact {
		flex-direction: row;
	}
	.contact-info {
		padding-top: 0;
	}
}

img {
	vertical-align: middle;
}
a {
	color: inherit;
	text-decoration: none;
}
.table,
.table-all {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	display: table;
}
.table-all {
	border: 1px solid #ccc;
}
.bordered tr,
.table-all tr {
	border-bottom: 1px solid #ddd;
}
.striped tbody tr:nth-child(even) {
	background-color: #f1f1f1;
}
.table-all tr:nth-child(odd) {
	background-color: #fff;
}
.table-all tr:nth-child(even) {
	background-color: #f1f1f1;
}
.hoverable tbody tr:hover,
.ul.hoverable li:hover {
	background-color: #ccc;
}
.centered tr th,
.centered tr td {
	text-align: center;
}
.table td,
.table th,
.table-all td,
.table-all th {
	padding: 8px 8px;
	display: table-cell;
	text-align: left;
	vertical-align: top;
}
.table th:first-child,
.table td:first-child,
.table-all th:first-child,
.table-all td:first-child {
	padding-left: 16px;
}
.btn,
.button {
	border: none;
	display: inline-block;
	padding: 8px 16px;
	vertical-align: middle;
	overflow: hidden;
	text-decoration: none;
	color: inherit;
	background-color: inherit;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
}
.tickets-btn {
	margin-bottom: 16px;
}
.btn:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btn,
.button {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.disabled,
.btn:disabled,
.button:disabled {
	cursor: not-allowed;
	opacity: 0.3;
}
.disabled *,
:disabled * {
	pointer-events: none;
}
.btn.disabled:hover,
.btn:disabled:hover {
	box-shadow: none;
}
.badge,
.tag {
	background-color: #000;
	color: #fff;
	display: inline-block;
	padding-left: 8px;
	padding-right: 8px;
	text-align: center;
}
.badge {
	border-radius: 50%;
}
.ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.ul li {
	padding: 8px 16px;
	border-bottom: 1px solid #ddd;
}
.ul li:last-child {
	border-bottom: none;
}
.tooltip,
.display-container {
	position: relative;
}
.tooltip .text {
	display: none;
}
.tooltip:hover .text {
	display: inline-block;
}
.ripple:active {
	opacity: 0.5;
}
.ripple {
	transition: opacity 0s;
}
.input {
	padding: 8px;
	display: block;
	border: none;
	border-bottom: 1px solid #ccc;
	width: 100%;
}
.select {
	padding: 9px 0;
	width: 100%;
	border: none;
	border-bottom: 1px solid #ccc;
}
.dropdown-click,
.dropdown-hover {
	position: relative;
	display: inline-block;
	cursor: pointer;
}
.dropdown-hover:hover .dropdown-content {
	display: block;
}
.dropdown-hover:first-child,
.dropdown-click:hover {
	background-color: #ccc;
	color: #000;
}
.dropdown-hover:hover > .button:first-child,
.dropdown-click:hover > .button:first-child {
	background-color: #ccc;
	color: #000;
}
.dropdown-content {
	cursor: auto;
	color: #000;
	background-color: #fff;
	display: none;
	position: absolute;
	min-width: 160px;
	margin: 0;
	padding: 0;
	z-index: 1;
}
.check,
.radio {
	width: 24px;
	height: 24px;
	position: relative;
	top: 6px;
}
.sidebar {
	height: 100%;
	width: 200px;
	background-color: #fff;
	position: fixed !important;
	z-index: 1;
	overflow: auto;
}
.bar-block .dropdown-hover,
.bar-block .dropdown-click {
	width: 100%;
}
.bar-block .dropdown-hover .dropdown-content,
.bar-block .dropdown-click .dropdown-content {
	min-width: 100%;
}
.bar-block .dropdown-hover .button,
.bar-block .dropdown-click .button {
	width: 100%;
	text-align: left;
	padding: 8px 16px;
}
.main,
#main {
	transition: margin-left 0.4s;
}
.modal {
	z-index: 3;
	display: none;
	padding-top: 100px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
	margin: auto;
	background-color: #fff;
	position: relative;
	padding: 0;
	outline: 0;
	width: 600px;
}
.bar {
	width: 100%;
	overflow: hidden;
}
.center .bar {
	display: inline-block;
	width: auto;
}
.bar .bar-item {
	padding: 8px 16px;
	float: left;
	width: auto;
	border: none;
	display: block;
	outline: 0;
}
.lang-btns {
	float: left;
}
.bar-lang {
	display: inline-block;
	max-width: 100px;
}
@media (min-width: 600px) {
	.lang-btns {
		float: right;
	}
}
.lang-img {
	margin-right: 8px;
	width: 40%;
	height: auto;
}
.bar .dropdown-hover,
.bar .dropdown-click {
	position: static;
	float: left;
}
.bar .button {
	white-space: normal;
}
.bar-block .bar-item {
	width: 100%;
	display: block;
	padding: 8px 16px;
	text-align: left;
	border: none;
	white-space: normal;
	float: none;
	outline: 0;
}
.bar-block.center .bar-item {
	text-align: center;
}
.block {
	display: block;
	width: 100%;
}
.responsive {
	display: block;
	overflow-x: auto;
}
.container:after,
.container:before,
.panel:after,
.panel:before,
.row:after,
.row:before,
.row-padding:after,
.row-padding:before,
.cell-row:before,
.cell-row:after,
.clear:after,
.clear:before,
.bar:before,
.bar:after {
	content: "";
	display: table;
	clear: both;
}
.col,
.half,
.third,
.twothird,
.threequarter,
.quarter {
	width: 100%;
}
.third {
	margin-top: 5%;
}
.col.s1 {
	width: 8.33333%;
}
.col.s2 {
	width: 16.66666%;
}
.col.s3 {
	width: 24.99999%;
}
.col.s4 {
	width: 33.33333%;
}
.col.s5 {
	width: 41.66666%;
}
.col.s6 {
	width: 49.99999%;
}
.col.s7 {
	width: 58.33333%;
}
.col.s8 {
	width: 66.66666%;
}
.col.s9 {
	width: 74.99999%;
}
.col.s10 {
	width: 83.33333%;
}
.col.s11 {
	width: 91.66666%;
}
.col.s12 {
	width: 99.99999%;
}
@media (min-width: 601px) {
	.col.m1 {
		width: 8.33333%;
	}
	.col.m2 {
		width: 16.66666%;
	}
	.col.m3,
	.quarter {
		width: 24.99999%;
	}
	.col.m4 {
		width: 33.33333%;
	}
	.col.m5 {
		width: 41.66666%;
	}
	.col.m6,
	.half {
		width: 49.99999%;
	}
	.col.m7 {
		width: 58.33333%;
	}
	.col.m8,
	.twothird {
		width: 66.66666%;
	}
	.col.m9,
	.threequarter {
		width: 74.99999%;
	}
	.col.m10 {
		width: 83.33333%;
	}
	.col.m11 {
		width: 91.66666%;
	}
	.col.m12 {
		width: 99.99999%;
	}
}
@media (min-width: 993px) {
	.col.l1 {
		width: 8.33333%;
	}
	.col.l2 {
		width: 16.66666%;
	}
	.col.l3 {
		width: 24.99999%;
	}
	.col.l4 {
		width: 33.33333%;
	}
	.col.l5 {
		width: 41.66666%;
	}
	.col.l6 {
		width: 49.99999%;
	}
	.col.l7 {
		width: 58.33333%;
	}
	.col.l8 {
		width: 66.66666%;
	}
	.col.l9 {
		width: 74.99999%;
	}
	.col.l10 {
		width: 83.33333%;
	}
	.col.l11 {
		width: 91.66666%;
	}
	.col.l12 {
		width: 99.99999%;
	}
}
.rest {
	overflow: hidden;
}
.stretch {
	margin-left: -16px;
	margin-right: -16px;
}
.content,
.auto {
	margin-left: auto;
	margin-right: auto;
}
.content {
	max-width: 100%;
	margin-top: 46px;
}
.auto {
	max-width: 1140px;
}
.cell-row {
	display: table;
	width: 100%;
}
.cell {
	display: table-cell;
}
.cell-top {
	vertical-align: top;
}
.cell-middle {
	vertical-align: middle;
}
.cell-bottom {
	vertical-align: bottom;
}
.hide {
	display: none !important;
}
.show-block,
.show {
	display: block !important;
}
.show-inline-block {
	display: inline-block !important;
}
@media (max-width: 1205px) {
	.auto {
		max-width: 95%;
	}
}
@media (max-width: 600px) {
	.modal-content {
		margin: 0 10px;
		width: auto !important;
	}
	.modal {
		padding-top: 30px;
	}
	.dropdown-hover.mobile .dropdown-content,
	.dropdown-click.mobile .dropdown-content {
		position: relative;
	}
	.hide-small {
		display: none !important;
	}
	.mobile {
		display: block;
		width: 100% !important;
	}
	.bar-item.mobile,
	.dropdown-hover.mobile,
	.dropdown-click.mobile {
		text-align: center;
	}
	.dropdown-hover.mobile,
	.dropdown-hover.mobile .btn,
	.dropdown-hover.mobile .button,
	.dropdown-click.mobile,
	.dropdown-click.mobile .btn,
	.dropdown-click.mobile .button {
		width: 100%;
	}
}
@media (max-width: 768px) {
	.modal-content {
		width: 500px;
	}
	.modal {
		padding-top: 50px;
	}
}
@media (min-width: 993px) {
	.modal-content {
		width: 900px;
	}
	.hide-large {
		display: none !important;
	}
	.sidebar.collapse {
		display: block !important;
	}
}
@media (max-width: 992px) and (min-width: 601px) {
	.hide-medium {
		display: none !important;
	}
}
@media (max-width: 992px) {
	.sidebar.collapse {
		display: none;
	}
	.main {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.auto {
		max-width: 100%;
	}
}
.top {
	position: fixed;
	width: 100%;
	z-index: 1;
	top: 0;
	transition: top 0.2s;
}
.bottom {
	bottom: 0;
}
.overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
}
.display-topleft {
	position: absolute;
	left: 0;
	top: 0;
}
.display-topright {
	position: absolute;
	right: 0;
	top: 0;
}
.display-bottomleft {
	position: absolute;
	left: 0;
	bottom: 0;
}
.display-bottomright {
	position: absolute;
	right: 0;
	bottom: 0;
}
.display-middle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}
.display-left {
	position: absolute;
	top: 50%;
	left: 0%;
	transform: translate(0%, -50%);
	-ms-transform: translate(-0%, -50%);
}
.display-right {
	position: absolute;
	top: 50%;
	right: 0%;
	transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
}
.display-topmiddle {
	position: absolute;
	left: 50%;
	top: 0;
	transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
}
.display-bottommiddle {
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
}
.display-container:hover .display-hover {
	display: block;
}
.display-container:hover span.display-hover {
	display: inline-block;
}
.display-hover {
	display: none;
}
.display-position {
	position: absolute;
}
.circle {
	border-radius: 50%;
}
.round-small {
	border-radius: 2px;
}
.round,
.round-medium {
	border-radius: 4px;
}
.round-large {
	border-radius: 8px;
}
.round-xlarge {
	border-radius: 16px;
}
.round-xxlarge {
	border-radius: 32px;
}
.row-padding,
.row-padding > .half,
.row-padding > .third,
.row-padding > .twothird,
.row-padding > .threequarter,
.row-padding > .quarter,
.row-padding > .col {
	padding: 0 8px;
}
.container,
.panel {
	padding: 0.01em 16px;
}
.panel {
	margin-top: 16px;
	margin-bottom: 16px;
}
.code,
.codespan {
	font-family: Consolas, "courier new";
	font-size: 16px;
}
.code {
	width: auto;
	background-color: #fff;
	padding: 8px 12px;
	border-left: 4px solid #4caf50;
	word-wrap: break-word;
}
.codespan {
	color: crimson;
	background-color: #f1f1f1;
	padding-left: 4px;
	padding-right: 4px;
	font-size: 110%;
}
.card,
.card-2 {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.card-4,
.hover-shadow:hover {
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}
.spin {
	animation: spin 2s infinite linear;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
.animate-fading {
	animation: fading 10s infinite;
}
@keyframes fading {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.animate-opacity {
	animation: opac 0.8s;
}
@keyframes opac {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.animate-top {
	position: relative;
	animation: animatetop 0.4s;
}
@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
.animate-left {
	position: relative;
	animation: animateleft 0.4s;
}
@keyframes animateleft {
	from {
		left: -300px;
		opacity: 0;
	}
	to {
		left: 0;
		opacity: 1;
	}
}
.animate-right {
	position: relative;
	animation: animateright 0.4s;
}
@keyframes animateright {
	from {
		right: -300px;
		opacity: 0;
	}
	to {
		right: 0;
		opacity: 1;
	}
}
.animate-bottom {
	position: relative;
	animation: animatebottom 0.4s;
}
@keyframes animatebottom {
	from {
		bottom: -300px;
		opacity: 0;
	}
	to {
		bottom: 0;
		opacity: 1;
	}
}
.animate-zoom {
	animation: animatezoom 0.6s;
}
@keyframes animatezoom {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}
.animate-input {
	transition: width 0.4s ease-in-out;
}
.animate-input:focus {
	width: 100% !important;
}
.opacity,
.hover-opacity:hover {
	opacity: 0.6;
}
.opacity-off,
.hover-opacity-off:hover {
	opacity: 1;
}
.opacity-max {
	opacity: 0.25;
}
.opacity-min {
	opacity: 0.75;
}
.greyscale-max,
.grayscale-max,
.hover-greyscale:hover,
.hover-grayscale:hover {
	filter: grayscale(100%);
}
.greyscale,
.grayscale {
	filter: grayscale(75%);
}
.greyscale-min,
.grayscale-min {
	filter: grayscale(50%);
}
.sepia {
	filter: sepia(75%);
}
.sepia-max,
.hover-sepia:hover {
	filter: sepia(100%);
}
.sepia-min {
	filter: sepia(50%);
}
.tiny {
	font-size: 10px !important;
}
.small {
	font-size: 12px !important;
}
.medium {
	font-size: 15px !important;
}
.large {
	font-size: 18px !important;
}
.xlarge {
	font-size: 24px !important;
}
.xxlarge {
	font-size: 36px !important;
}
.xxxlarge {
	font-size: 48px !important;
}
.jumbo {
	font-size: 64px !important;
}
.left-align {
	text-align: left !important;
}
.right-align {
	text-align: right !important;
}
.justify {
	text-align: justify !important;
}
.center {
	text-align: center !important;
}
.border-0 {
	border: 0 !important;
}
.border {
	border: 1px solid #ccc !important;
}
.border-top {
	border-top: 1px solid #ccc !important;
}
.border-bottom {
	border-bottom: 1px solid #ccc !important;
}
.border-left {
	border-left: 1px solid #ccc !important;
}
.border-right {
	border-right: 1px solid #ccc !important;
}
.topbar {
	border-top: 6px solid #ccc !important;
}
.bottombar {
	border-bottom: 6px solid #ccc !important;
}
.leftbar {
	border-left: 6px solid #ccc !important;
}
.rightbar {
	border-right: 6px solid #ccc !important;
}
.section,
.code {
	margin-top: 16px !important;
	margin-bottom: 16px !important;
}
.margin {
	margin: 16px !important;
}
.margin-top {
	margin-top: 16px !important;
}
.margin-bottom {
	margin-bottom: 16px !important;
}
.margin-left {
	margin-left: 16px !important;
}
.margin-right {
	margin-right: 16px !important;
}
.padding-small {
	padding: 4px 8px !important;
}
.padding {
	padding: 8px 16px !important;
}
.padding-large {
	padding: 12px 12px !important;
}
@media (min-width: 600px) {
	.padding-large {
		padding: 12px 24px !important;
	}
}
.padding-16 {
	padding-top: 16px !important;
	padding-bottom: 16px !important;
}
.padding-24 {
	padding-top: 24px !important;
	padding-bottom: 24px !important;
}
.padding-32 {
	padding-top: 32px !important;
	padding-bottom: 32px !important;
}
.padding-48 {
	padding-top: 48px !important;
	padding-bottom: 48px !important;
}
.padding-64 {
	padding-top: 64px !important;
	padding-bottom: 64px !important;
}
.padding-top-64 {
	padding-top: 64px !important;
}
.padding-top-48 {
	padding-top: 48px !important;
}
.padding-top-32 {
	padding-top: 32px !important;
}
.padding-top-24 {
	padding-top: 24px !important;
}
.left {
	float: left !important;
}
.right {
	float: right !important;
}
.button:hover {
	color: #000 !important;
	background-color: #ccc !important;
}
.transparent,
.hover-none:hover {
	background-color: transparent !important;
}
.hover-none:hover {
	box-shadow: none !important;
}
/* Colors */
.amber,
.hover-amber:hover {
	color: #000 !important;
	background-color: #ffc107 !important;
}
.aqua,
.hover-aqua:hover {
	color: #000 !important;
	background-color: #00ffff !important;
}
.blue,
.hover-blue:hover {
	color: #fff !important;
	background-color: #2196f3 !important;
}
.light-blue,
.hover-light-blue:hover {
	color: #000 !important;
	background-color: #87ceeb !important;
}
.brown,
.hover-brown:hover {
	color: #fff !important;
	background-color: #795548 !important;
}
.cyan,
.hover-cyan:hover {
	color: #000 !important;
	background-color: #00bcd4 !important;
}
.blue-grey,
.hover-blue-grey:hover,
.blue-gray,
.hover-blue-gray:hover {
	color: #fff !important;
	background-color: #607d8b !important;
}
.green,
.hover-green:hover {
	color: #fff !important;
	background-color: #4caf50 !important;
}
.light-green,
.hover-light-green:hover {
	color: #000 !important;
	background-color: #8bc34a !important;
}
.indigo,
.hover-indigo:hover {
	color: #fff !important;
	background-color: #3f51b5 !important;
}
.khaki,
.hover-khaki:hover {
	color: #000 !important;
	background-color: #f0e68c !important;
}
.lime,
.hover-lime:hover {
	color: #000 !important;
	background-color: #cddc39 !important;
}
.orange,
.hover-orange:hover {
	color: #000 !important;
	background-color: #ff9800 !important;
}
.deep-orange,
.hover-deep-orange:hover {
	color: #fff !important;
	background-color: #ff5722 !important;
}
.pink,
.hover-pink:hover {
	color: #fff !important;
	background-color: #e91e63 !important;
}
.purple,
.hover-purple:hover {
	color: #fff !important;
	background-color: #9c27b0 !important;
}
.deep-purple,
.hover-deep-purple:hover {
	color: #fff !important;
	background-color: #673ab7 !important;
}
.red,
.hover-red:hover {
	color: #fff !important;
	background-color: #f44336 !important;
}
.sand,
.hover-sand:hover {
	color: #000 !important;
	background-color: #fdf5e6 !important;
}
.teal,
.hover-teal:hover {
	color: #fff !important;
	background-color: #009688 !important;
}
.yellow,
.hover-yellow:hover {
	color: #000 !important;
	background-color: #ffeb3b !important;
}
.white,
.hover-white:hover {
	color: #000 !important;
	background-color: #fff !important;
}
.black,
.hover-black:hover {
	color: #fff !important;
	background-color: #222 !important;
}
.grey,
.hover-grey:hover,
.gray,
.hover-gray:hover {
	color: #000 !important;
	background-color: #9e9e9e !important;
}
.light-grey,
.hover-light-grey:hover,
.light-gray,
.hover-light-gray:hover {
	color: #000 !important;
	background-color: #f1f1f1 !important;
}
.dark-grey,
.hover-dark-grey:hover,
.dark-gray,
.hover-dark-gray:hover {
	color: #fff !important;
	background-color: #616161 !important;
}
.pale-red,
.hover-pale-red:hover {
	color: #000 !important;
	background-color: #ffdddd !important;
}
.pale-green,
.hover-pale-green:hover {
	color: #000 !important;
	background-color: #ddffdd !important;
}
.pale-yellow,
.hover-pale-yellow:hover {
	color: #000 !important;
	background-color: #ffffcc !important;
}
.pale-blue,
.hover-pale-blue:hover {
	color: #000 !important;
	background-color: #ddffff !important;
}
.text-amber,
.hover-text-amber:hover {
	color: #ffc107 !important;
}
.text-aqua,
.hover-text-aqua:hover {
	color: #00ffff !important;
}
.text-blue,
.hover-text-blue:hover {
	color: #2196f3 !important;
}
.text-light-blue,
.hover-text-light-blue:hover {
	color: #87ceeb !important;
}
.text-brown,
.hover-text-brown:hover {
	color: #795548 !important;
}
.text-cyan,
.hover-text-cyan:hover {
	color: #00bcd4 !important;
}
.text-blue-grey,
.hover-text-blue-grey:hover,
.text-blue-gray,
.hover-text-blue-gray:hover {
	color: #607d8b !important;
}
.text-green,
.hover-text-green:hover {
	color: #4caf50 !important;
}
.text-light-green,
.hover-text-light-green:hover {
	color: #8bc34a !important;
}
.text-indigo,
.hover-text-indigo:hover {
	color: #3f51b5 !important;
}
.text-khaki,
.hover-text-khaki:hover {
	color: #b4aa50 !important;
}
.text-lime,
.hover-text-lime:hover {
	color: #cddc39 !important;
}
.text-orange,
.hover-text-orange:hover {
	color: #ff9800 !important;
}
.text-deep-orange,
.hover-text-deep-orange:hover {
	color: #ff5722 !important;
}
.text-pink,
.hover-text-pink:hover {
	color: #e91e63 !important;
}
.text-purple,
.hover-text-purple:hover {
	color: #9c27b0 !important;
}
.text-deep-purple,
.hover-text-deep-purple:hover {
	color: #673ab7 !important;
}
.text-red,
.hover-text-red:hover {
	color: #f44336 !important;
}
.text-sand,
.hover-text-sand:hover {
	color: #fdf5e6 !important;
}
.text-teal,
.hover-text-teal:hover {
	color: #009688 !important;
}
.text-yellow,
.hover-text-yellow:hover {
	color: #d2be0e !important;
}
.text-white,
.hover-text-white:hover {
	color: #fff !important;
}
.text-black,
.hover-text-black:hover {
	color: #000 !important;
}
.text-grey,
.hover-text-grey:hover,
.text-gray,
.hover-text-gray:hover {
	color: #757575 !important;
}
.text-light-grey,
.hover-text-light-grey:hover,
.text-light-gray,
.hover-text-light-gray:hover {
	color: #f1f1f1 !important;
}
.text-dark-grey,
.hover-text-dark-grey:hover,
.text-dark-gray,
.hover-text-dark-gray:hover {
	color: #3a3a3a !important;
}
.border-amber,
.hover-border-amber:hover {
	border-color: #ffc107 !important;
}
.border-aqua,
.hover-border-aqua:hover {
	border-color: #00ffff !important;
}
.border-blue,
.hover-border-blue:hover {
	border-color: #2196f3 !important;
}
.border-light-blue,
.hover-border-light-blue:hover {
	border-color: #87ceeb !important;
}
.border-brown,
.hover-border-brown:hover {
	border-color: #795548 !important;
}
.border-cyan,
.hover-border-cyan:hover {
	border-color: #00bcd4 !important;
}
.border-blue-grey,
.hover-border-blue-grey:hover,
.border-blue-gray,
.hover-border-blue-gray:hover {
	border-color: #607d8b !important;
}
.border-green,
.hover-border-green:hover {
	border-color: #4caf50 !important;
}
.border-light-green,
.hover-border-light-green:hover {
	border-color: #8bc34a !important;
}
.border-indigo,
.hover-border-indigo:hover {
	border-color: #3f51b5 !important;
}
.border-khaki,
.hover-border-khaki:hover {
	border-color: #f0e68c !important;
}
.border-lime,
.hover-border-lime:hover {
	border-color: #cddc39 !important;
}
.border-orange,
.hover-border-orange:hover {
	border-color: #ff9800 !important;
}
.border-deep-orange,
.hover-border-deep-orange:hover {
	border-color: #ff5722 !important;
}
.border-pink,
.hover-border-pink:hover {
	border-color: #e91e63 !important;
}
.border-purple,
.hover-border-purple:hover {
	border-color: #9c27b0 !important;
}
.border-deep-purple,
.hover-border-deep-purple:hover {
	border-color: #673ab7 !important;
}
.border-red,
.hover-border-red:hover {
	border-color: #f44336 !important;
}
.border-sand,
.hover-border-sand:hover {
	border-color: #fdf5e6 !important;
}
.border-teal,
.hover-border-teal:hover {
	border-color: #009688 !important;
}
.border-yellow,
.hover-border-yellow:hover {
	border-color: #ffeb3b !important;
}
.border-white,
.hover-border-white:hover {
	border-color: #fff !important;
}
.border-black,
.hover-border-black:hover {
	border-color: #000 !important;
}
.border-grey,
.hover-border-grey:hover,
.border-gray,
.hover-border-gray:hover {
	border-color: #9e9e9e !important;
}
.border-light-grey,
.hover-border-light-grey:hover,
.border-light-gray,
.hover-border-light-gray:hover {
	border-color: #f1f1f1 !important;
}
.border-dark-grey,
.hover-border-dark-grey:hover,
.border-dark-gray,
.hover-border-dark-gray:hover {
	border-color: #616161 !important;
}
.border-pale-red,
.hover-border-pale-red:hover {
	border-color: #ffe7e7 !important;
}
.border-pale-green,
.hover-border-pale-green:hover {
	border-color: #e7ffe7 !important;
}
.border-pale-yellow,
.hover-border-pale-yellow:hover {
	border-color: #ffffcc !important;
}
.border-pale-blue,
.hover-border-pale-blue:hover {
	border-color: #e7ffff !important;
}
